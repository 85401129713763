import { graphql } from "gatsby";
import React from "react";
import BetterModels from "../components/annotation_Pages/BetterModels";
import HeroSection from "../components/annotation_Pages/HeroSection";
import CenterCardSection from "../components/autoSegment_Pages/CenterCardSection";
import Layout from "../components/layout";
import RowsSection from "../components/sar_Pages/RowsSection";
import "../styles/page/annotation_pages.scss";
import { pickSlice } from "../utilities/helpers";
import SEO from "../components/seo";
import PosterVideo from '../assets/posters/auto-segment-hero-video-asset.jpg'

const AutoSegmentPage = ({ location, data }) => {
  const _data = data?.prismicAutoSegmentPage?.data || {};
  const [trusted_brands_list] = pickSlice(_data, "trusted_brands_list");
  const [better_model] = pickSlice(_data, "better_model");
  const [g2_rating] = pickSlice(_data, "g2_rating");
  const feature_cards = pickSlice(_data, "feature_cards");
  const [firsSection, ...page_section] = pickSlice(_data, "page_section");

  return (
    <Layout mainClasses="">
      <article className="relative max-w-7xl mx-auto md:pt-44 pt-32 text-center px-5">
        <HeroSection
          trusted_brands_list={trusted_brands_list}
          imageSRC={_data?.hero_image?.url}
          videoSRC={_data?.hero_video?.url}
          imageWidth={_data?.hero_image?.dimensions?.width}
          imageHeight={_data?.hero_image?.dimensions?.height}
          heading={_data?.page_heading?.html}
          description={_data?.page_description?.text}
          pageName="new auto segment"
          containerClasses="max-w-[49rem]"
          videoPoster={PosterVideo}
        />

        <RowsSection
          isReverse={false}
          btnLink={firsSection?.primary?.learn_more_link?.url}
          btnText="Learn more"
          description={firsSection?.primary?.section_description?.text}
          heading={firsSection?.primary?.section_heading?.text}
          imgAlt={firsSection?.primary?.section_image?.alt}
          imgSrc={firsSection?.primary?.section_image?.url}
          imageWidth={firsSection?.primary?.section_image?.dimensions?.width}
          imageHeight={firsSection?.primary?.section_image?.dimensions?.height}
          subHeading={firsSection?.primary?.section_label?.text}
        />

        <section className="flex lg:flex-row flex-col gap-x-4 max-w-7xl mx-auto justify-center">
          {feature_cards?.map((item, index) => {
            const isReverse = index % 2 === 0;
            return (
              <CenterCardSection
                isReverse={isReverse}
                key={index}
                imgAlt={item?.primary?.section_image?.alt}
                imgSrc={item?.primary?.section_image?.url}
                imageWidth={item?.primary?.section_image?.dimensions?.width}
                imageHeight={item?.primary?.section_image?.dimensions?.height}
                videoSrc={item?.primary?.section_video?.url}
                description={item?.primary?.section_description?.text}
                heading={item?.primary?.section_heading?.text}
              />
            );
          })}
        </section>
        {page_section?.map((item, index) => {
          const isReverse = index % 2 === 0;
          return (
            <RowsSection
              key={item?.id}
              isReverse={isReverse}
              btnLink={item?.primary?.learn_more_link?.url}
              btnText="Learn more"
              description={item?.primary?.section_description?.text}
              heading={item?.primary?.section_heading?.text}
              imgAlt={item?.primary?.section_image?.alt}
              imgSrc={item?.primary?.section_image?.url}
              imageWidth={item?.primary?.section_image?.dimensions?.width}
              imageHeight={item?.primary?.section_image?.dimensions?.height}
              videoSrc={item?.primary?.section_video?.url}
              subHeading={item?.primary?.section_label?.text}
            />
          );
        })}
      </article>
      <BetterModels
        heading={better_model?.primary?.section_heading?.text}
        location={location}
        description={better_model?.primary?.section_description?.text}
        ctaHeading={better_model?.primary?.cta_heading?.text}
        ctaDescription={better_model?.primary?.cta_description?.text}
        modelTypes={better_model?.items}
        g2RatingData={g2_rating?.items}
        pageName="new auto segment"
      />
    </Layout>
  );
};

export default AutoSegmentPage;

export const query = graphql`
  query {
    prismicAutoSegmentPage {
      data {
        body {
          ... on PrismicAutoSegmentPageDataBodyBetterModel {
            id
            slice_type
            primary {
              cta_description {
                html
                text
              }
              cta_heading {
                html
                text
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
            }
            items {
              section_feature_description {
                html
                text
              }
              section_feature_heading {
                html
                text
              }
              section_feature_icon {
                alt
                url
              }
            }
          }
          ... on PrismicAutoSegmentPageDataBodyFeatureCards {
            id
            slice_type
            primary {
              section_description {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_video {
                url
              }
              section_heading {
                html
                text
              }
            }
          }
          ... on PrismicAutoSegmentPageDataBodyG2Rating {
            id
            slice_type
            items {
              section_heading {
                html
                text
              }
              section_description {
                html
                text
              }
            }
          }
          ... on PrismicAutoSegmentPageDataBodyPageSection {
            id
            slice_type
            primary {
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_video {
                url
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicAutoSegmentPageDataBodyTrustedBrandsList {
            id
            slice_type
            primary {
              section_heading {
                html
                text
              }
            }
            items {
              brand_logo {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
        hero_image {
          alt
          url
          dimensions {
            height
            width
          }
        }
        hero_video {
          url
        }
        meta_description {
          html
          text
        }
        page_description {
          html
          text
        }
        meta_heading {
          html
          text
        }
        page_heading {
          html
          text
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicAutoSegmentPage?.data || {};

  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};
